import * as React from "react"

import { useStaticQuery, PageProps, Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Features from "../components/features"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string;
    }
  }
}

const ServicesPage: React.FC<PageProps<DataProps>> = (props) => (
  <Layout {...props}>
    <Seo title="Services" />
    <article className="prose lg:prose-xl py-24 mx-auto">

      <h1>Services</h1>

      <p className="lead">After conducting an in-depth client assessment, Morag utilises a range of effective strategies, such as  Solutions Focussed,  Ericksonian, Cognitive Behavioural Therapy, blended  to create a bespoke plan aimed at reaching the clients goals.</p>
      <p>Morag provides one to one consultations either face to face or remotely via zoom video link, with an  Initial free telephone call to discuss individual requirements. There are a few conditions for which hypnosis would not be the appropriate strategy. If you decide to proceed and book a consultation the first session lasts approximately 90 minutes with the expected number of follow up sessions agreed at that time. Follow up sessions last one hour.</p>
      <p><Link to="/blog/hypnotherapy-via-zoom">For further information about hypnosis via zoom please see our blog</Link></p>
      <p>Morag provides an audio recording following the first session to allow the client to practice the wonderful skill of self hypnosis and get the most of of hypnotherapy</p>
      <p>As well as individual consultations Morag provides group information sessions and  workshops.</p>

      <h2>Professional Bodies</h2>

      <p>Morag is a member of National Council for Hypnotherapy (NCH), the leading regulatory body for hypnotherapists. She is also a member of the prestigious Complimentary Natural Health Council (CNHC) which regulates  all complementary health practitioners. The Department of Health recommends that you consult with a therapist who is registered with the CNHC.</p>

      <h2>Terms and Conditions</h2>

      <p><strong>Each session costs £60. Fee payable on booking, with 48 hour cancellation policy.</strong></p>
      <p>Morag provides a confidential professional service and adheres strictly to Government Data Protection Regulations (GDPR) and also to the National Council for Hypnotherapy (NCH) professional regulations. All information discussed  between the client and  Morag would be treated confidentially.</p>
      <p>Where appropriate and with the client's permission, Morag would liaise with the clients General Practitioner or other Health Care Professionals.</p>

    </article>

    <Features elements={['why', 'what', 'zoom']} />
  </Layout>
)

export default ServicesPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
      siteMetadata {
        title
      }
    }
  }
`
